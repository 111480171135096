import { useEffect } from 'react';
import './App.css';

function App() {
  useEffect(() => {
    
  }, [])  

  return (
      <div className="App">
        <h1>???</h1>
        
      </div>
    );
}

export default App;
